import React from 'react';

const WelcomeText = (props) => {
    
    return(
        <div className='openingPage'>
        <div className='opening-first-text'>Welcome</div>
        <div className='opening-second-text'>to</div>
        <div className='opening-third-text'>my world</div>
        </div>
    )

}

export default WelcomeText